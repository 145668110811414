<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="main-box">
				<div class="mem_contents_header">
					<div class="title"><span class="mem_info"></span><p>회원가입/{{mberInfo.title}}</p></div>
					<div class="reg-step"><img src="/images/mem/process_bar_03.png" alt="절차"/></div>
				</div>
				<div class="mem_contents_body">
					<div class="body-top"><hr/><p>하이프로는 안전한 인터넷 서비스를 위해 본인인증을 받고 있습니다<span @click="pass()">.</span></p><hr/></div>
					<div class="info-box">
						<div class="phone-img">
							<img src="/images/mem/phone_img.png" alt="휴대폰본인인증"/>휴대폰 본인인증
							<div class="phone-info">
								본인명의의 휴대전화를 통하여 본인인증을 받으실 수 있습니다.<br/>
								통신사의 사정으로 휴대폰 인증을 위한 메시지를 받지 못하는 경우도 있습니다.
							</div>
						</div>
						<div class="phone-btn">
							<div class="btn-prv" @click="moveBack()">이전화면</div>
							<div class="btn-crt" @click="openCert()">휴대폰 본인인증 하기</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <input type="text" name="comm_id" 		:value="kcpcert.comm_id" />
		<input type="text" name="phone_no" 		:value="kcpcert.phone_no" />
		<input type="text" name="user_name" 	:value="kcpcert.user_name"/>
		<input type="text" name="birth_day" 	:value="kcpcert.birth_day" />
		<input type="text" name="sex_code"		:value="kcpcert.sex_code" />
		<input type="text" name="ci" 			:value="kcpcert.ci" />
		<input type="text" name="di" 			:value="kcpcert.di" />			 -->
	
	</div>
</template>

<script>
export default {
	data() {
		return {
			kcpcert : {},
			mberInfo : {},
		}
	},

	beforeMount() {
		if(this.$route.params) {
			this.mberInfo = this.$route.params.forwardData;
		}else {
			this.$router.replace('MEM003M01');
		}
	},

	methods : {
		openCert() {
			this.$.kcpCert(this, function(this_obj, res) {
				this_obj.kcpcert = res;
				this_obj.mberchk();
			});

		},

		mberchk() {
			var param = {
				di : this.kcpcert.di,
				mberDivCd : this.mberInfo.mberDivCd
			};

			this.$.httpPost('api/mem/memberChk', param,'')
				.then(res => {
					if(res.data.resultCode == '000') {
						if(res.data.idMap) {
							alert('이미 등록된 회원입니다.');
							return false;
						}else {
							this.mberInfo.kcpcert = this.kcpcert;
							this.$router.push({name:'MEM004M03', params:{secrets : this.mberInfo}});
						}
					}
				})
		},
		
		moveBack() {
			this.$router.go(-1);
		},

		pass() {
			if(this.$store.state.profile.name == '운영') {
				var admininput = prompt("입력");
				if(admininput == 'coffee ') {
					this.mberInfo.pass = true;
					this.$router.push({name:'MEM004M03', params:{secrets : this.mberInfo}});
				}
			} else {
				this.mberInfo.pass = true;
				this.$router.push({name:'MEM004M03', params:{secrets : this.mberInfo}});
			}

		}
	}
}
</script>